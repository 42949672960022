module.exports = {
  pathPrefix: '/',
  siteTitle: 'edouardr.fr',
  siteTitleAlt: 'The personal website of Edouard Riviere',
  siteTitleShort: 'edouardr',
  siteUrl: process.env.ROOT_URL || 'https://edouardr.fr',
  siteLanguage: 'en',
  siteLogo: 'images/logo.png',
  siteDescription: "Edouard's interests regarding software engineering",
  keywords: ['Software Engineer', 'Blog'],
  author: {
    name: 'Edouard Riviere',
  },
  organization: 'Zen-Prod',
  twitter: 'https://twitter.com/edouardr_/',
  twitterHandle: '@edouardr_',
  github: 'https://github.com/edouardr/',
  linkedin: 'https://www.linkedin.com/in/edouardr/',
  themeColor: '#1F6537',
  backgroundColor: '#091E11',
};
